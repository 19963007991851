.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #2c4763b6;
  box-shadow: rgba(33, 57, 136, 0.39) 0px 3px 8px;
  padding: 0px 0px;
  margin:0px 0px;
  text-align: center;
}

.contact-form {
  width: 100%;
  height:100%;
  align-items: center;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin:0px 0px;
}

.form-title {
  font-size: 2rem;
  font-weight: 500;
  color: #f5f5f5;
  margin-bottom: 20px;
}

.form-group {
  width: 100%;
  margin-bottom: 3rem;
  position: relative;
}

.form-input,
.form-textarea {
  width: 100%;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  color: #e8e8e8;
  border: none;
  border-bottom: 2px solid #e8e8e8;
  background: transparent;
  outline: none;
  resize: none;
}

.form-label {
  position: absolute;
  left: 0;
  top: 0rem;
  font-size: 15px;
  color: #e8e8e8;
  transition: 0.3s ease;
  text-transform: uppercase;
}

.form-group input:focus + .form-label,
.form-group input:valid + .form-label,
.form-group textarea:focus + .form-label,
.form-group textarea:valid + .form-label {
  top: -1.5rem;
  font-size: 0.9rem;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.cta {
  position: relative;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.2s ease;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 30px;
  background: #b1dae7;
  transition: 0.3s ease;
}

.cta span {
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  color: #333;
}

.cta svg {
  position: relative;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #234567;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #ffffff;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

@media screen and (max-width: 768px) {
  .form-container {
    padding: 10px;
  }
  .form-label {
    position: absolute;
    left: 0;
    top: 0rem;
    font-size: 15px;
    color: #e8e8e8;
    transition: 0.3s ease;
    text-transform: uppercase;
  }
  
  .form-group input:focus + .form-label,
  .form-group input:valid + .form-label,
  .form-group textarea:focus + .form-label,
  .form-group textarea:valid + .form-label {
    top: -1rem;
    font-size: 0.9rem;
  }

  .contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
  }

  .form-title {
    font-size: 1.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .cta {
    padding: 8px 16px;
  }
}



.filterNosotrosSection1 {
  position: absolute;
  background-color: #192d3dc0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-top: 0.5px solid white;
}

.ColSection1Nosotros {
  padding: 0px 10px !important;
  margin: 0px 0px !important;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.espaciadoContSection1Nosotros {
  padding: 5%;
  text-align: center;
  width: 60%;
  z-index: 2;

  overflow: hidden;
}

.ColSection1Nosotros h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
}

.ColSection1Nosotros p {
  color: white;
  font-style: normal;
  font-size: 20px;
  background-color: transparent;
  margin-top: 1rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .ContainerPrincipalNosotros {
    text-align: center;
    margin-top: 0px 0px !important;
    padding: 0px 0px !important;
  }

  .filterNosotrosSection1 {
    position: absolute;
    background-color: #192d3dc0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-top: 0.5px solid white;
  }

  .ColSection1Nosotros {
    padding: 0px 10px !important;
    margin: 1rem 0px !important;
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .espaciadoContSection1Nosotros {
    padding: 5%;
    text-align: center;
    width: 90%;
    z-index: 2;
    overflow: hidden;
  }

  .ColSection1Nosotros h2 {
    font-weight: 400;
    font-size: 30px;
    color: white;
    text-align: left;
  }

  .ColSection1Nosotros p {
    color: white;
    font-style: normal;
    font-size: 22px;
    background-color: transparent;
    margin-top: 10px;
    text-align: left;
  }

}

/* ------------------------------SECTION 2 ----------------*/
.filterNosotrosSection2 {
  position: absolute;
  background-color: #131c23ee;
  width: 100%;
  border-top: 1px solid white;
  height: 100%;
  z-index: 1;
}

.espaciadoContSection2Nosotros {
  padding: 5% 6%;
  text-align: center;
  width: 90%;
  z-index: 2;
  overflow: hidden;
}

.ColSection2Nosotros {
  padding: 0px 10px !important;
  margin: 10px 0px !important;
  text-align: left;
  position: relative;
  z-index: 2;
  /* background-color: red; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: white;
}

.ColSection2Nosotros h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
}

.ColSection2Nosotros p {
  color: #cccccc;
  font-size: 20px;
  background-color: transparent;
  margin-top: 20px;
  text-align: left;
}
.ColSection2Nosotros strong {
  color: #ffffff;
  font-size: 20px;
  background-color: transparent;
  margin-top: 20px;
  text-align: left;
}

.ImgSection2Nosotros{
  width: 80%;
  height: 80%;
  object-fit: cover;
  box-shadow: 0px 0px 10px 0px #000000;
  border-radius: 10px;
  background-color: #243c4ae6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {


  .filterNosotrosSection2 {
    position: absolute;
    border-top: 1px solid white;
    background-color: #192d3de0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .espaciadoContSection2Nosotros {
    padding: 2% 5%;
    text-align: center;
    width: 90%;
    z-index: 2;
    overflow: hidden;
  }

  .ColSection2Nosotros {
    padding: 0px 10px;
    margin: 1rem 0px;
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }


  .ColSection2Nosotros h2 {
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: left;
  }

  .ColSection2Nosotros p {
    color: #cccccc;
    font-size: 20px;
    background-color: transparent;
    margin-top: 20px;
    text-align: left;
  }

}
.VideoNosotros {
  position: relative;
  width: 100%;
  height: 100%;
  /* Aspect ratio 16:9 */
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; 
  margin: 0px 0px !important;
  padding: 0px 0px !important;
  background-color: #294963;
}

.responsive-iframe {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  overflow: hidden;
  object-fit: cover;
}

.filterVideoNosotros {
  position: absolute;
  background-color: #00000000;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0px 0px;
  margin: 0px 0px;
  border: 0px 0px;
}

.TituloFilaNosotros {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-style: oblique;
  font-size: 40px;
}

.TextoFilaNosotros {
  color: black;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  background-color: transparent;
  margin-top: 1rem;
}
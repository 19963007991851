/* Propiedades de Navbar luego del scrolldown */
.NavbarStyle.scrolled {
  position: absolute;
  background-color: #28455fef; /* Cambia el color cuando se desplaza  28455fbd - 28455fef*/
  top: 0;
  width: 100%;
  z-index: 1000;
  border: none;
  border-radius: 0px;
  letter-spacing: 2px;
  transition: ease-in-out 0.8s;
}

.NavbarStyle.scrolled .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  border: none;
  border-radius: 0px;
  margin: 0px 0px;
}

.NavbarStyle.scrolled .nav-link {
  text-decoration: none; /* Sin subrayado */
  color: #f5f5f5; /* Color del texto */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
}

/* .NavbarStyle.scrolled {
  text-decoration: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #ffffff; 
  border-radius: 5px; 
} */

/* .NavbarStyle.scrolled {
  background-color: #d1d1d1; 
  border-color: #7c8fa2; 
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #ffffff;
} */

.NavbarStyle.scrolled .nav-link:hover {
  transition: ease-in-out 0.2s;
  background-color: #e0e0e0ab; /* Color de fondo al pasar el mouse */
  border-radius: 5px;
  color: #333;
  cursor: pointer;
}

/* Dropdown scroldown */
.NavbarStyle.scrolled .dropdown-menu {
  background-color: #28455fef; /* Fondo del dropdown */
  border: none; /* Eliminar borde del dropdown */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra del dropdown */
  animation: Cascade 0.8s;
  color: #f5f5f5;
}

.NavbarStyle.scrolled .dropdown-item {
  color: #f5f5f5 !important; /* Color de texto */
  transition: color 0.3s ease, background-color 0.3s ease; /* Transición suave para el color de texto y fondo */
  cursor: pointer;
}

/* Hover del dropdown cuando es scrolldown */
.NavbarStyle.scrolled .dropdown-item:hover {
  background-color: #e0e0e0; /* Color de fondo al pasar el mouse */
  color: #333 !important; /* Color de texto */
}

/* ---------------------Navbar estilos iniciales -----------------------------------------*/
.NavbarStyle {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 100; /* Asegura que el navbar esté por encima de otros elementos */
  background-color: transparent; /* Color de fondo del navbar */
  text-align: center;
  transition: ease-in-out 1s;
  text-decoration: none;
  margin: 0px 0px;
}

/* Enlaces del Navbar */

.NavbarStyle .nav-link {
  color: #f5f5f5;
  text-decoration: none;
  border-radius: 10px;
  transition: color 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  margin: 0px 0px;
  cursor: pointer;
}

.NavbarStyle .nav-link:hover {
  transition: ease-in-out 0.2s;
  background-color: #cacaca; /* Color de fondo al pasar el mouse */
  color: #333; /* Cambio de color de texto al pasar el mouse */
  border-radius: 10px;
  cursor: pointer;
}

.NavbarStyle .nav {
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  top: 0px;
  border: none;
  border-radius: 0px;
  letter-spacing: 2px;
  margin: 2rem 0px;
}

/* Afecta todo el navbar */
/* .nav-link h2{
  color: red ; 
  border-radius: 10px;
  font-size: 40px;
  justify-content: center;
  text-decoration: none;
  justify-content: space-evenly;
  
} */

/* .NavBrand {

} */

.title-disex {
  color: white;
  font-size: 60px;
  font-weight: bold;
  top: 0px;
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: right;
  height: 100%;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}



.navbar-brand {
  color: #f5f5f5;
  text-decoration: none;
  border-radius: 8px;
  transition: color 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  margin: 0px 40px;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 5;
  }
}

/* Dropdown normal */
.NavbarStyle .dropdown-menu {
  background-color: #f5f5f5; /* Fondo del dropdown */
  color: #333 !important;
  border: none; /* Eliminar borde del dropdown */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra del dropdown */
  animation: Cascade 0.8s;
  margin: 0px 0px;
}

/* .NavbarStyle .dropdown-menu:hover {
  background-color: #f5f5f5; 
  color: #333; 
} */

.NavbarStyle .dropdown-item {
  color: #333 !important; /* Color de texto */
  transition: color 0.3s ease, background-color 0.3s ease; /* Transición suave para el color de texto y fondo */
  cursor: pointer;
}
/* .NavbarStyle .dropdown-item:hover {

  color: #333;
} */

@keyframes Cascade {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hamburguesa (NavbarToggler) */
.navbar-toggler-icon {
  background-color: transparent; /* Color de las líneas de la hamburguesa */
}
.navbar-toggler {
  background-color: #f5f5f5 !important; /* Color de fondo del botón de hamburguesa */
  border-radius: 5px; /* Borde redondeado */
  border: none; /* Eliminar borde */
}

/* Icono del botón de hamburguesa */
/* .navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  background-color: red !important;
} */

/* AJUSTAR LOGOTIPO PARA DISEÑO RESPONSIVE */
/* AJUSTAR DESPLEGABLES EN DISEÑO RESPONSIVE */
/* @media (max-width: 768px) {
  .dropdown-menu {
    background-color: #f5f5f5;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px !important;
  }
} */

.NavbarStyle .ButtonContactGeneral {
  background-color: #28689265;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border: none;
}

.NavbarStyle .ButtonContactGeneral:hover {
  background-color: #ffffff;
  color: #333;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.NavbarStyle.scrolled .ButtonContactGeneral {
  background-color: rgb(255, 255, 255);
  color: #333;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border: none;
}

.NavbarStyle.scrolled .ButtonContactGeneral:hover {
  background-color: #ffffffcf;
  color: #333;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {

  .title-disex{
    font-size: 45px;
  }

  .navbar-toggler{
    background-color: transparent !important;
    color: white !important;
    filter: invert(1);
  }

  .ButtonContactGeneral{
    display: none !important;
  }

}

.buttonChangeLanguage{
  background-color: transparent;
  color: white;
  cursor: pointer;
  border: none;
}
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet);
* {
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.footer {
  position: relative;
  bottom: 0;
  padding: 0;
  color: white;
  background-color: #28455f;
  text-align: center;
  width: 100%;
  overflow: hidden;
  border-radius: 0px;
  animation: fadeIn 1s ease-in-out;
}
.footer,
.footer-logo {
  height: 100%;
}

.container-footer {
  overflow: hidden !important;
  width: 100%;
}

.footer-logo {
  width: 100%;
  padding: 0px 0px;
  margin: 0px 0px;
  animation: slideIn 1s ease-in-out;
  height: 5vh;
  /* height: 5vh; */
  width:70%;
  object-fit: cover;
}

.ColumnLogoFooter {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  width: 5%;
  display: flex;
  justify-content: center;
}

.ColumnTextFooter {
  font-size: 15px;
  color: #fff;
  padding: 0px 0px;
  margin: 0px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}



.footer .copyright,
.privacy-policy,
.terms-conditions {
  margin: 0px 0px;
  color: white;
  width: 100%;
  padding: 0px 0px;
  height: auto;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

  
.social-icon {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  width: 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
  animation: slideIn 1s ease-in-out;


  }

@media screen and (max-width: 768px) {
  .footer {
    position: relative;
    bottom: 0;
    padding: 0;
    color: white;
    background-color: #28455f;
    text-align: center;
    overflow: hidden;
    border-radius: 0;
    animation: fadeIn 1s ease-in-out;
  }
  .footer,
  .container-footer {
    height: 100%;
    width: 100%;
  }
  .ColumnLogoFooter {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footer-logo {
    position: relative;
    top: 0px;
    overflow: hidden;
    width: 50%;
    padding: 0px 0px;
    margin: 0px 0px;
    animation: slideIn 1s ease-in-out;
    height: auto;
  }
  .footer .copyright,
  .privacy-policy,
  .terms-conditions {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
    color: white;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ColumnTextFooter {
    font-size: 15px;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 5vh;
    overflow-x: hidden !important;
  }

  
  .social-icon {
    padding: 0px 0px;
    margin: 0px 0px;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    height: auto;
    }
}

/* FONDO DE LA SECCIÓN 1 */
.filter1 {
  position: absolute;
  background-color: #1d3345b5;
  width: 100%;
  height: 100%;
}

.ContainerSeccion1Home {
  height: 100%;
  width: 100%;
  text-align: left;
  margin: 0px 0px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: 0%;
  padding-left: 10% !important;
}

.tittle1seccion1Home {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 3px;
  width: 100%;
  padding: 20px 0px;
}

.ColPrendaHome {
  padding: 0px 0px;
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.ColPrendaHome2 {
  padding: 0px 0px;
  margin: 0px 0px;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 70% !important;
}

.Parrafoseccion1Home {
  display: flex;
  flex-direction: column;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  width: 90%;
  margin: 0px 0px;
  padding: 20px 0px;
  text-align: left;
  letter-spacing: 2px;

}



/*-------------------------------- SECCIÓN 2 ---------------------------------*/

.OverFlowNone {
  overflow: hidden;
}

.Col1Divtextil,
.Col2Divtextil {
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  width: 100%;
  height: 100%;
}

.Col1Divtextil {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Col2Divtextil{
  padding: 0px 0px !important;
  margin: 0px 0px;
  text-align: center;
  width: 100%;
}

.filter2 {
  position: absolute;
  background-color: #2829297e;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.tittle1section2Home {
  font-size: 50px;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  letter-spacing: 3px;
  padding: 50% 0px;
}

.bgPaqCom {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  object-fit: cover;
  background-position: center;
  padding: 0px 0px;
  margin: 0px 0px;
}


/*-------------------------- SECCIÓN 3 ----------------------------*/

.tittle1section3Home {
  position: relative;
  font-size: 40px;
  font-weight: 500;
  color: #f5f5f5;
  text-align: left;
  width: 100%;
  height: auto;
  padding: 40px 20px;
}

.subtitle1section3Home {
  font-size: 30px;
  color: #fff;
  display: flex;
  text-align: left;
  width: 80%;
  padding: 10px 30px;
  position: relative;
}

.ColCardsInfoHome {
  margin: 0px 0px;

  height: 100%;
}

.RowCardsInfoHome {
  margin: 0px 0px;
  padding: 20px 0px;
  width: 100%;
  height: auto;
}

.filter3 {
  background-color: #223549c4;
  width: 100%;
  height: 100%;
  position: absolute; 
}



/* --------------------- SSECCIÓN 4------------------------------- */

@media screen and (max-width: 300px) {

  .filter1 {
    position: absolute;
    background-color: #213545bc;
    width: 100%;
    height: 100%;
  }

  .ContainerSeccion1Home {
    top: 0px;
    height: 100%;
    width: 100%;
    text-align: left;
    padding: 0px 0px;
  }

  .tittle1seccion1Home {
    font-size: 30px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 4px;
    width: 80%;
    padding: 0px 0px;
    margin: 5rem 0px;

  }

  .Parrafoseccion1Home {
    font-size: 30px;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-weight: 400;
    text-align: start;
    letter-spacing: 0px;
    margin-top: 2rem;
    width: 80%;
  }

  .ColPrendaHome {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
  }

  /* SESION 2 ---------------------- */
  /* FONDOS  */
}
@media screen and (max-width: 768px) {

  /* Sesión 1 */

  .filter1 {
    position: absolute !important;
    background-color: #152838b7;
    width: 100%;
    height: 100%;
  }

  .ContainerSeccion1Home {
    height: 100%;
    width: 100%;
    text-align: left;
    margin: 0px 0px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 0px 0px;
    padding-left: 10%;
    overflow: hidden;
  }

  .tittle1seccion1Home {
    font-size: 50px;
    color: #fff;
    letter-spacing: 3px;
    width: 100%;
    padding: 0px 0px;
    margin: 0px 0px;
  }

  .ColPrendaHome {
    padding: 0px 0px;
    margin: 0px 0px;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .ColPrendaHome2 {
    padding: 0px 0px;
    margin: 0px 0px;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100% !important;
  }

  .Parrafoseccion1Home {
    display: flex;
    flex-direction: column;
    font-size: 30px;
    color: #fff;
    font-weight: 500;
    width: 100%;
    margin: 0px 0px;
    padding: 0px 0px;
    text-align: left;
    letter-spacing: 2px;

  }

  /* SESION 2 ---------------------- */
  /* FONDOS  */

  .OverFlowNone {
    overflow: hidden;
  }
  
  .Col1Divtextil,
  .Col2Divtextil {
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  
  .filter2 {
    position: absolute;
    background-color: #2828297e;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .tittle1section2Home {
    font-size: 40px;
    color: #fff;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 5% 0px;
  }


  /* SECCION 3 ------------------------- */


  .tittle1section3Home {
    font-size: 30px;
    font-weight: 500;
    color: #f5f5f5;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
  }

  .subtitle1section3Home {
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0px 0px;
  }

  .ColCardsInfoHome {
    margin: 0px 0px;
    padding: 0px 0px;
    width: 100%;
    height:16% ;
  }

  .filter3 {
    position: absolute;
    background-color: #2c4763d0;
    width: 100%;
    height: 100%;
    margin: 0px 0px;
  }
}
.button-transparent-left,
.button-transparent-right,
.button-transparent-section1Home,
.button-transparent-section2Home,
.button-transparent-section2Home-right,
.button-transparent-section2Home-left,
.button-transparent-general-center,
.button-transparent-center-NotFound,
.button-transparent-general-center-Nosotros,
.button-transparent-center-card-hover {
  background-color: rgba(0, 0, 0, 0.384) !important;
  box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  border: solid 0px !important;
  cursor: pointer;
  border-radius: 9px;
  transition: background-color 0.3s ease;
  padding: 10px 10px !important;
  text-align: center;
}

.button-transparent-left:hover,
.button-transparent-right:hover,
.button-transparent-section1Home:hover,
.button-transparent-section2Home:hover,
.button-transparent-section2Home-right:hover,
.button-transparent-section2Home-left:hover,
.button-transparent-general-center:hover,
.button-transparent-center-NotFound:hover,
.button-transparent-general-center-Nosotros:hover,
.button-transparent-center-card-hover {
  background-color: #ffffffad !important;
  color: #333 !important;

}

.button-transparent-left {
  width: 50%;
}

.button-transparent-right {
  width: 50%;
}

.button-null {
  background-color: transparent !important;
  color: transparent !important;
  visibility: hidden !important;
  cursor: none;
  visibility: hidden;
}


.button-transparent-general-center {
  position: absolute;
}

.button-transparent-general-center-Nosotros {
  position: absolute;
  transform: translate(10%, 15%);
}

.button-transparent-center-card-hover {
  position: absolute;
  transform: translate(0%, 5%);
  top: 60%;
}

.button-transparent-section2Home-left {
  position: relative;
  z-index: 5;
}

.button-transparent-section2Home-right {
  position: relative;
  z-index: 5;
}

.button-transparent-section1Home {
  margin-top: 2rem;
  letter-spacing: 3px;
  padding: 10px 30px !important;
}

@media screen and (max-width: 780px) {
  .button-transparent-general-center {
    position: absolute;
    transform: translate(-50%, 50%);
  }

  .button-transparent-center-NotFound {
    position: absolute;
    transform: translate(-50%, 50%);
  }

  .button-transparent-general-center-Nosotros {
    position: absolute;
    transform: translate(-50%, 20%);
  }
}

/* .button-transparent-left {
  top: 100%;
}

.button-transparent-right {
  top: 100%;
} */

@media screen and (max-width: 400px) {
  .button-transparent-section1Home {
    bottom: 0px;
    letter-spacing: 5px;
  }
}
.media-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}


.background-image {
  width: 100%;
  /* Video ocupará todo el ancho */
  height: 100vh;
  /* Video ocupará todo el alto */
  object-fit: cover;
  /* Ajustar el video al tamaño del contenedor */
  margin: 0px 0px;
  padding: 0px 0px;
}

.overlay {

  position: absolute;
  top: 0;
  padding-top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.texto-central {

  margin-top: 100px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: justify;
  /* Centrar el texto */
}

.header-title {
  font-size: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  width: 100%;
  padding: 0px 0px;
}

.header-subtitle {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.header-description {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
}


.buttonContainerHeader {
  justify-content: center;
  padding: 0px 0px;
  margin: 2rem 0px;
  width: 100%;
  height: 50%;
  text-align: center;
  margin-bottom: 5px;
}

.Col2ButtonsHeader {
  position: relative;
  width: 100%;
  text-align: left;

}

.Col1ButtonsHeader {
  width: 100%;
  margin: 0px 0px;
  text-align: right;

}

@media screen and (max-width: 768px) {

  .texto-central {
    top: 50% !important;
    width: 80%;
  }

  .background-image {
    width: 100%;
    object-fit: cover;
    margin: 0px 0px;
    padding: 0px 0px;
    height: 100vh;
  }

  .header-title {
    font-size: 35px !important;
  }

  .header-subtitle {
    display: none;
    font-size: 20px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .texto-central {
    top: 40%;
    left: 50%;
    margin-top: 0;
    transform: translate(-50%, -50%);
    text-align: center;
  }


  .header-description {
    font-size: 18px;
    font-weight: 200;
    text-align: center;
    letter-spacing: 0px;
  }
  .buttonContainerHeader {
    justify-content: center;
    padding: 0px 0px;
    margin: 2rem 0px;
    width: 100%;
    height: 50%;
    text-align: center;
  }
  .Col2ButtonsHeader {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .Col1ButtonsHeader {
    width: 100%;
    margin: 0px 0px;
    text-align: center;
    margin: 2rem 0px;
  }
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #2c4763b6;
  box-shadow: rgba(33, 57, 136, 0.39) 0px 3px 8px;
  padding: 0px 0px;
  margin:0px 0px;
  text-align: center;
}

.contact-form {
  width: 100%;
  height:100%;
  align-items: center;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  margin:0px 0px;
}

.form-title {
  font-size: 2rem;
  font-weight: 500;
  color: #f5f5f5;
  margin-bottom: 20px;
}

.form-group {
  width: 100%;
  margin-bottom: 3rem;
  position: relative;
}

.form-input,
.form-textarea {
  width: 100%;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  color: #e8e8e8;
  border: none;
  border-bottom: 2px solid #e8e8e8;
  background: transparent;
  outline: none;
  resize: none;
}

.form-label {
  position: absolute;
  left: 0;
  top: 0rem;
  font-size: 15px;
  color: #e8e8e8;
  transition: 0.3s ease;
  text-transform: uppercase;
}

.form-group input:focus + .form-label,
.form-group input:valid + .form-label,
.form-group textarea:focus + .form-label,
.form-group textarea:valid + .form-label {
  top: -1.5rem;
  font-size: 0.9rem;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.cta {
  position: relative;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.2s ease;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 30px;
  background: #b1dae7;
  transition: 0.3s ease;
}

.cta span {
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  color: #333;
}

.cta svg {
  position: relative;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #234567;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #ffffff;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

@media screen and (max-width: 768px) {
  .form-container {
    padding: 10px;
  }
  .form-label {
    position: absolute;
    left: 0;
    top: 0rem;
    font-size: 15px;
    color: #e8e8e8;
    transition: 0.3s ease;
    text-transform: uppercase;
  }
  
  .form-group input:focus + .form-label,
  .form-group input:valid + .form-label,
  .form-group textarea:focus + .form-label,
  .form-group textarea:valid + .form-label {
    top: -1rem;
    font-size: 0.9rem;
  }

  .contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
  }

  .form-title {
    font-size: 1.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .cta {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 768px)    {
  /* .ContainerBg{
    height: 300vh !important;
  } */
}
.ContainerContacInfo {
  padding: 0px 0px;
  overflow: hidden;
  position: relative;
  height: 100%;
}

/* .filter1ContactInfo, */
.Col2section1InfoContact {
  height: 100%;
  position: relative;
  padding: 0px 0px;
  margin: 0px 0px;
  overflow: hidden;
}

.RowNosotros {
  width: 100%;
  height: 100%;
  padding: 0px 10px !important;
  margin: 0px 0px !important;
  overflow: hidden;
}

.filter1ContactInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #13161ac5;
}

.Col1section1InfoContact {
  margin: 0px 0px;
  padding: 0px 0px !important;
  height: 100%;
  object-fit: cover;
  position: relative;
}

/*------------- ESPACIADO PARA AMBAS COLUMNAS */
.espaciadosection1InfoContact {
  padding: 5% 50px;
  border: none;
  background-color: #2c4763b6;
}

.espaciado2section1InfoContact {
  border: none;
  border-radius: 15px;
}

.espaciado2section1InfoContact,
.espaciadosection1InfoContact {
  margin: 50px 0px;
  border: none;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  

}

/*-------------- CONTENIDO ESTILOS  */
.tittlesection1InfoContact {
  font-size: 40px;
  color: #fff;
  position: relative;
  letter-spacing: 3px;
  text-align: center;
  display: flex;
  flex-direction: column;

}


.subtittlesection1InfoContact {
  font-size: 25px;
  color: #fff;
  position: relative;
  text-align: center;
}

.Parrafosection3Home {
  font-size: 22px;
  color: #fff;
  position: relative;
  text-align: left;
  padding: 0px 0px;
  justify-content: center;
  margin: 3.5px 0px;
}

/* ------------Columna 2  */
.Col2section1InfoContact {
  margin: 0px 0px !important;
  padding: 0px 0px !important;
  height: 100%;
  position: relative;
}


/* ----------- ICONOS  */
.Social-media1,
.Social-media2 {
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 20% !important;
}

.Social-media1 {
  text-align: right;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.LinkedinIcon {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Social-media2 {
  text-align: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.InstagramIcon {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-height: 1024px) {
  .filter1ContactInfo {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .Col2section1InfoContact {
    margin: 0px 0px !important;
    padding: 0px 10px !important;
    height: 100%;
    position: relative;
    border-radius: 15px;
  }
}

@media screen and (max-width: 768px) {
  .filter1ContactInfo {
    width: 100%;
  }

  .RowNosotros {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    overflow: hidden;
  }

  .subtittlesection1InfoContact {
    display: none;
  }

  .Col1section1InfoContact {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
    height: 100% !important;
    position: relative;
    text-align: center;
  }

  .espaciadosection1InfoContact {
    padding: 50px 10px;
    background-color: #2c4763b6;
    border: none;
    border-radius: 15px;
  }

  .tittlesection1InfoContact {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
  }

  .Parrafosection3Home {
    margin: 0px 0px !important;
    padding: 10px 0px !important;
    font-size: 20px;
    color: #fff;
    position: relative;
    text-align: center;
    padding: 0px 0px;
    justify-content: center;
  }

  .Col2section1InfoContact {
    margin: 0px 0px;
    padding: 0px 0px;
    width: 100%;
    position: relative;
  }

  .espaciado2section1InfoContact {
    margin: 0% 0%;
    padding: 0px 0px;
    justify-content: center;
    border: none;
    border-radius: 15px;
    text-align: center;
    width: 100%;
  }

  .ColIconNosotros {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
  }

  .Social-media1,
  .Social-media2 {
    width: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 0px !important;

  }

  .Social-media1 {
    width: 100%;

  }

  .LinkedinIcon {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0px 0px !important;
    padding: 0px 0px !important;
  }

  .Social-media2 {
    width: 100%;
  }

  .InstagramIcon {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.ContainerCardInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.cardInfo {
  --bg: #f7f7f8;
  --hover-bg: #3a5877de;
  --hover-text: #f5f5f5;
  width: 100%;
  max-width: 100%; /* Ajusta este valor según sea necesario */
  height: 100%;
  max-height: 400px;
  text-align: center;
  background: var(--bg);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0.6, 0.4, 0, 1), transform 0.15s ease;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.cardInfo > strong {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.035em;
}

.cardInfo span {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hover-text);
  border-radius: 5px;
  font-weight: bold;
  top: 100%;
  transition: all 0.4s cubic-bezier(0.6, 0.4, 0, 1);
  cursor: pointer;
  text-align: center;
  padding: 0 10px;
}

.cardInfo:hover span {
  top: 0;
  font-size: 1.2em;
}

.cardInfo:hover {
  background: var(--hover-bg);
}

.cardInfo:hover > div,
.cardInfo:hover > strong {
  opacity: 0;
}

.icon img {
  width: 100%;
  height: auto;
  max-height: 100px;
  min-height: 60px;
  height: 70px;
}

@media screen and (max-width: 768px) {
  .cardInfo {
    padding: 1rem;
  }

  .ContainerCardInfo {
    padding: 1rem;
  }
}

/* Propiedades de Navbar luego del scrolldown */
.NavbarStyle.scrolled {
  position: absolute;
  background-color: #28455fef; /* Cambia el color cuando se desplaza  28455fbd - 28455fef*/
  top: 0;
  width: 100%;
  z-index: 1000;
  border: none;
  border-radius: 0px;
  letter-spacing: 2px;
  transition: ease-in-out 0.8s;
}

.NavbarStyle.scrolled .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  border: none;
  border-radius: 0px;
  margin: 0px 0px;
}

.NavbarStyle.scrolled .nav-link {
  text-decoration: none; /* Sin subrayado */
  color: #f5f5f5; /* Color del texto */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
}

/* .NavbarStyle.scrolled {
  text-decoration: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #ffffff; 
  border-radius: 5px; 
} */

/* .NavbarStyle.scrolled {
  background-color: #d1d1d1; 
  border-color: #7c8fa2; 
  transition: background-color 0.3s ease, border-color 0.3s ease;
  color: #ffffff;
} */

.NavbarStyle.scrolled .nav-link:hover {
  transition: ease-in-out 0.2s;
  background-color: #e0e0e0ab; /* Color de fondo al pasar el mouse */
  border-radius: 5px;
  color: #333;
  cursor: pointer;
}

/* Dropdown scroldown */
.NavbarStyle.scrolled .dropdown-menu {
  background-color: #28455fef; /* Fondo del dropdown */
  border: none; /* Eliminar borde del dropdown */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra del dropdown */
  animation: Cascade 0.8s;
  color: #f5f5f5;
}

.NavbarStyle.scrolled .dropdown-item {
  color: #f5f5f5 !important; /* Color de texto */
  transition: color 0.3s ease, background-color 0.3s ease; /* Transición suave para el color de texto y fondo */
  cursor: pointer;
}

/* Hover del dropdown cuando es scrolldown */
.NavbarStyle.scrolled .dropdown-item:hover {
  background-color: #e0e0e0; /* Color de fondo al pasar el mouse */
  color: #333 !important; /* Color de texto */
}

/* ---------------------Navbar estilos iniciales -----------------------------------------*/
.NavbarStyle {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 100; /* Asegura que el navbar esté por encima de otros elementos */
  background-color: transparent; /* Color de fondo del navbar */
  text-align: center;
  transition: ease-in-out 1s;
  text-decoration: none;
  margin: 0px 0px;
}

/* Enlaces del Navbar */

.NavbarStyle .nav-link {
  color: #f5f5f5;
  text-decoration: none;
  border-radius: 10px;
  transition: color 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  margin: 0px 0px;
  cursor: pointer;
}

.NavbarStyle .nav-link:hover {
  transition: ease-in-out 0.2s;
  background-color: #cacaca; /* Color de fondo al pasar el mouse */
  color: #333; /* Cambio de color de texto al pasar el mouse */
  border-radius: 10px;
  cursor: pointer;
}

.NavbarStyle .nav {
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  top: 0px;
  border: none;
  border-radius: 0px;
  letter-spacing: 2px;
  margin: 2rem 0px;
}

/* Afecta todo el navbar */
/* .nav-link h2{
  color: red ; 
  border-radius: 10px;
  font-size: 40px;
  justify-content: center;
  text-decoration: none;
  justify-content: space-evenly;
  
} */

/* .NavBrand {

} */

.title-disex {
  color: white;
  font-size: 60px;
  font-weight: bold;
  top: 0px;
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: right;
  height: 100%;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}



.navbar-brand {
  color: #f5f5f5;
  text-decoration: none;
  border-radius: 8px;
  transition: color 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  margin: 0px 40px;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 5;
  }
}

/* Dropdown normal */
.NavbarStyle .dropdown-menu {
  background-color: #f5f5f5; /* Fondo del dropdown */
  color: #333 !important;
  border: none; /* Eliminar borde del dropdown */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra del dropdown */
  animation: Cascade 0.8s;
  margin: 0px 0px;
}

/* .NavbarStyle .dropdown-menu:hover {
  background-color: #f5f5f5; 
  color: #333; 
} */

.NavbarStyle .dropdown-item {
  color: #333 !important; /* Color de texto */
  transition: color 0.3s ease, background-color 0.3s ease; /* Transición suave para el color de texto y fondo */
  cursor: pointer;
}
/* .NavbarStyle .dropdown-item:hover {

  color: #333;
} */

@keyframes Cascade {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hamburguesa (NavbarToggler) */
.navbar-toggler-icon {
  background-color: transparent; /* Color de las líneas de la hamburguesa */
}
.navbar-toggler {
  background-color: #f5f5f5 !important; /* Color de fondo del botón de hamburguesa */
  border-radius: 5px; /* Borde redondeado */
  border: none; /* Eliminar borde */
}

/* Icono del botón de hamburguesa */
/* .navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  background-color: red !important;
} */

/* AJUSTAR LOGOTIPO PARA DISEÑO RESPONSIVE */
/* AJUSTAR DESPLEGABLES EN DISEÑO RESPONSIVE */
/* @media (max-width: 768px) {
  .dropdown-menu {
    background-color: #f5f5f5;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px !important;
  }
} */

.NavbarStyle .ButtonContactGeneral {
  background-color: #28689265;
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border: none;
}

.NavbarStyle .ButtonContactGeneral:hover {
  background-color: #ffffff;
  color: #333;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.NavbarStyle.scrolled .ButtonContactGeneral {
  background-color: rgb(255, 255, 255);
  color: #333;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border: none;
}

.NavbarStyle.scrolled .ButtonContactGeneral:hover {
  background-color: #ffffffcf;
  color: #333;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

@media (max-width: 768px) {

  .title-disex{
    font-size: 45px;
  }

  .navbar-toggler{
    background-color: transparent !important;
    color: white !important;
    filter: invert(1);
  }

  .ButtonContactGeneral{
    display: none !important;
  }

}

.buttonChangeLanguage{
  background-color: transparent;
  color: white;
  cursor: pointer;
  border: none;
}


.filterNosotrosSection1 {
  position: absolute;
  background-color: #192d3dc0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-top: 0.5px solid white;
}

.ColSection1Nosotros {
  padding: 0px 10px !important;
  margin: 0px 0px !important;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.espaciadoContSection1Nosotros {
  padding: 5%;
  text-align: center;
  width: 60%;
  z-index: 2;

  overflow: hidden;
}

.ColSection1Nosotros h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
}

.ColSection1Nosotros p {
  color: white;
  font-style: normal;
  font-size: 20px;
  background-color: transparent;
  margin-top: 1rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .ContainerPrincipalNosotros {
    text-align: center;
    margin-top: 0px 0px !important;
    padding: 0px 0px !important;
  }

  .filterNosotrosSection1 {
    position: absolute;
    background-color: #192d3dc0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-top: 0.5px solid white;
  }

  .ColSection1Nosotros {
    padding: 0px 10px !important;
    margin: 1rem 0px !important;
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .espaciadoContSection1Nosotros {
    padding: 5%;
    text-align: center;
    width: 90%;
    z-index: 2;
    overflow: hidden;
  }

  .ColSection1Nosotros h2 {
    font-weight: 400;
    font-size: 30px;
    color: white;
    text-align: left;
  }

  .ColSection1Nosotros p {
    color: white;
    font-style: normal;
    font-size: 22px;
    background-color: transparent;
    margin-top: 10px;
    text-align: left;
  }

}

/* ------------------------------SECTION 2 ----------------*/
.filterNosotrosSection2 {
  position: absolute;
  background-color: #131c23ee;
  width: 100%;
  border-top: 1px solid white;
  height: 100%;
  z-index: 1;
}

.espaciadoContSection2Nosotros {
  padding: 5% 6%;
  text-align: center;
  width: 90%;
  z-index: 2;
  overflow: hidden;
}

.ColSection2Nosotros {
  padding: 0px 10px !important;
  margin: 10px 0px !important;
  text-align: left;
  position: relative;
  z-index: 2;
  /* background-color: red; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: white;
}

.ColSection2Nosotros h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
}

.ColSection2Nosotros p {
  color: #cccccc;
  font-size: 20px;
  background-color: transparent;
  margin-top: 20px;
  text-align: left;
}
.ColSection2Nosotros strong {
  color: #ffffff;
  font-size: 20px;
  background-color: transparent;
  margin-top: 20px;
  text-align: left;
}

.ImgSection2Nosotros{
  width: 80%;
  height: 80%;
  object-fit: cover;
  box-shadow: 0px 0px 10px 0px #000000;
  border-radius: 10px;
  background-color: #243c4ae6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {


  .filterNosotrosSection2 {
    position: absolute;
    border-top: 1px solid white;
    background-color: #192d3de0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .espaciadoContSection2Nosotros {
    padding: 2% 5%;
    text-align: center;
    width: 90%;
    z-index: 2;
    overflow: hidden;
  }

  .ColSection2Nosotros {
    padding: 0px 10px;
    margin: 1rem 0px;
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }


  .ColSection2Nosotros h2 {
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: left;
  }

  .ColSection2Nosotros p {
    color: #cccccc;
    font-size: 20px;
    background-color: transparent;
    margin-top: 20px;
    text-align: left;
  }

}
.VideoNosotros {
  position: relative;
  width: 100%;
  height: 100%;
  /* Aspect ratio 16:9 */
  object-fit: cover;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; 
  margin: 0px 0px !important;
  padding: 0px 0px !important;
  background-color: #294963;
}

.responsive-iframe {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  overflow: hidden;
  object-fit: cover;
}

.filterVideoNosotros {
  position: absolute;
  background-color: #00000000;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0px 0px;
  margin: 0px 0px;
  border: 0px 0px;
}

.TituloFilaNosotros {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-style: oblique;
  font-size: 40px;
}

.TextoFilaNosotros {
  color: black;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  background-color: transparent;
  margin-top: 1rem;
}
.ContainerContac {
  padding: 0px 0px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.bgimgsection1Contact {
  position: relative; /* PARA QUE EL OBJETO QUE APARECE ENCIMA DEL FONDO SE POSICIONE RELATIVO A ESTE */
  background-attachment: fixed; /* PARA QUE LA IMAGEN DE FONDO NO SE MUEVA */
  background-position: center; /* PARA QUE LA IMAGEN DE FONDO SE CENTRE */
  background-repeat: no-repeat; /* PARA QUE LA IMAGEN DE FONDO NO SE REPITA */
  background-size: cover; /*PARA QUE LA IMAGEN DE FONDO CUBRA EL 100% DEL ALTO DE LA PANTALLA */
  background-image: url(/static/media/bgContacto.e250b32c.jpg);
  min-width: 100%; /* PARA QUE EL ALTO DEL FONDO SEA DEL 100% DEL ALTO DE LA PANTALLA */
  object-fit: cover;
}
.Col1section1Contact {
  margin: 0px;
  padding: 0px;
  object-fit: cover;
  position: relative;
  text-align: center;
}

.espaciadosection1Contact {
  margin: 10% 1px;
  padding: 0px 10%;
  background-color: #0b12184b;
  justify-content: left;
  text-align: left;
}

.tittlesection1Contact {
  font-size: 50px;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  position: relative;
  letter-spacing: 4px;
  text-align: center;
  top: 10%;
}

.subtittlesection1Contact {
  font-size: 30px;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  position: relative;
  text-align: left;
}

.Parrafosection1Contact {
  font-size: 20px;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  position: relative;
  text-align: left;
}

.filter2ContactInfo {
  position: absolute;
  background-color: #28292980;
  width: 100%;
  height: 100%;
}

.Col2section1Contact {
  margin: 0px;
  padding: 0px;
  height: 100%;
  object-fit: cover;
  position: relative;
}



.floatWhatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 50px;
  right: 40px;
  border-radius: 50%; /* Asegúrate de que sea un círculo */
  text-align: center;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-icon {
  transition: transform 0.3s, fill 0.3s;
}

.floatWhatsapp:hover .whatsapp-icon {
  transform: scale(1.1);
}


/* ------------------ SECCIÓN 1 ------------------- */

.bgDivisionTextil {
  background-image: url(/static/media/bgMaquinaTejHomePC.2da54aa5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  position: relative;
}

/* Largo del fondo y el filtro */
.bgDivisionTextil,
.filterDivText {
  height: 100%;
}

@media screen and (max-width: 768px) {

  .bgDivisionTextil,
  .filterDivText {
    height: 100%;
  }

}

.TittleSection1DivisionT {
  position: relative;
  font-size: 40px;
  font-weight: 500;
  color: #f5f5f5;
  text-align: left;
  width: 100%;
  padding-left: 5%;
  margin-top: 5rem;

}

.filterDivText {
  background-color: rgba(23, 55, 82, 0.929);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


/* ------------------ SECCIÓN 2 ------------------- */


.Row1Section2DivT {
  text-align: center;
  margin-top: 0px 0px !important;
  padding: 0px 0px !important;
  z-index: 1;
  position: relative;
  width: 90%;
  height: 50%;
  justify-content: center;
  left: 5%;
  top: 20%;
  border-radius: 10px;
}

.espaciadoContSection2DivT {
  padding: 10px 0px;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.TittleSection2DivT {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  z-index: 3;
  width: 50%;
  left: 5%;
  top: 5rem;
}

.TittleSection2DivT h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-decoration-thickness: 2px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-align: left;
}

.ColSection2DivT {
  padding: 5px 5px !important;
  margin: 0px 0px !important;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.ImgBgColDivT {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  margin: 1px 1px;
  padding: 1px 1px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
}


.CapaTextoSection2DivT {
  position: absolute;
  background-color: #294963d8;
  z-index: 2;
  color: #fff;
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 5%;
  width: 90%;
  height: 70%;
  top: 15%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #000000;
}


.CapaTextoSection2DivT h3 {
  position: absolute;
  /* background-color: #0fd43a93; */
  z-index: 3;
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: center;
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  border-radius: 8px;
  letter-spacing: 1px;
  word-wrap: break-word;
}

.filter1DivTSection2 {
  position: absolute;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.filter2DivTSection2 {
  position: absolute;
  background-color: #22659b36;
  width: 100%;
  height: 100%;
  z-index: 3;
}

@media screen and (max-width: 768px) {

  .Row1Section2DivT {
    text-align: center;
    margin-top: 0px 0px;
    padding: 0px 0px;
    z-index: 1;
    position: relative;
    background-color: #294963;
    width: 100%;
    height: 100%;
    justify-content: center;
    left: 10%;
    top: 5% !important;
  }

  .TittleSection2DivT {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #fff;
    position: relative;
    z-index: 3;
    width: 80% !important;
    left: 5%;
  }

  .TittleSection2DivT h2 {
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: left;
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid;
    text-decoration-thickness: 2px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-align: left;
  }

  .ColSection2DivT {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    text-align: center;
    width: 100%;
    height: 25% !important;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .ImgBgColDivT {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    margin: 0px 0px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .CapaTextoSection2DivT {
    position: absolute;
    background-color: #294963cc;
    z-index: 2;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 10%;
    width: 100%;
    height: 100%;
    top: 15%;
    word-spacing: normal;
    box-shadow: 0px 0px 10px 0px #000000;

  }


  .filter1DivTSection2 {
    position: absolute;
    background-color: #00000080;
    width: 100%;
    height: 100%;
    z-index: 1;

  }

  .filter2DivTSection2 {
  position: absolute;
  background-color: #1388e936;
  width: 100%;
  height: 100%;
  z-index: 3;
  }

}

@media screen and (max-width: 768px) {



  .Row1Section2DivT {
    text-align: center;
    margin-top: 0px 0px !important;
    padding: 0px 0px !important;
    z-index: 1;
    position: relative;
    background-color: #294963;
    width: 80%;
    height: 40%;
    justify-content: center;
    left: 10%;
    top: 10%;
  }

  .espaciadoContSection2DivT {
    padding: 10px 0px;
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .TittleSection2DivT {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #fff;
    position: relative;
    z-index: 3;
    width: 50%;
    left: 5%;
    top: 3rem;
  }

  .TittleSection2DivT h2 {
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: left;
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid;
    text-decoration-thickness: 2px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-align: left;
  }

  .ColSection2DivT {
    padding: 5px 5px !important;
    margin: 0px 0px !important;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .ImgBgColDivT {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    margin: 0px 0px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .CapaTextoSection2DivT {
    position: absolute;
    background-color: #294963cc;
    z-index: 2;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 10%;
    width: 80%;
    height: 60%;
    top: 15%;
    word-spacing: normal;
    box-shadow: 0px 0px 10px 0px #000000;

  }

}



/* ------------------ SECCIÓN 3 ------------------- */

.filter1DivTSection3 {
  position: absolute;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  z-index: 1;

}

.TittleSection3DivT {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  z-index: 3;
  width: 50%;
  left: 5%;
  top: 5rem;
}
.TittleSection3DivT h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-decoration-thickness: 2px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-align: left;
}

/* General container styles */
.columnas-figure {
  position: relative;
  width: 100%;
  height: 400px; /* Ajustar según sea necesario */
  overflow: hidden;
  border-radius: 5px;
  margin: 2rem 0px;
  overflow: hidden;
}

/* Figure styles */
.figure {
  position: relative;
  width: 100%;
  height: 400px; /* Ajustar según sea necesario */
  overflow: hidden;
  border-radius: 5px;
}

/* Image styles */
.img-card-hover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.5s ease;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-attachment: fixed;
}

/* Text overlay styles */
.capa-texto-card-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  color: white;
  opacity: 1; /* Inicialmente invisible */
  transition: opacity 0.5s ease;
  border-radius: 5px;
  cursor: pointer;
}

.title-card-hover {
  font-size: 30px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  margin: 0 10px; /* Espaciado interno */
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 20%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.text-card-hover{
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px;
  text-align: center;
  width: 80%;
  height: 30%;
}

/* .list-card-hover {
  font-size: 16px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  margin: 0px 0px;

  text-align: left;
  width: 100%;
  height: 100%;
  word-wrap: break-word;
} */

/* Hover effects */
.figure:hover .img-card-hover {
  transform: scale(1.1); /* Ampliar la imagen al pasar el mouse */
}

.figure:hover .capa-texto-card-hover {
  opacity: 0; /* Hacer visible la capa de texto */
}

@media screen and (max-width: 768px) {
  .columnas-figure {
    position: relative;
    width: 100%;
    height: 50vh; /* Ajustar según sea necesario */
    overflow: hidden;
    border-radius: 5px;
    margin: 1rem 0px;
  }

  /* Figure styles */
  .figure {
    position: relative;
    width: 100%;
    height: 400px; /* Ajustar según sea necesario */
    overflow: hidden;
    border-radius: 5px;
  }

  /* Image styles */
  .img-card-hover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.5s ease;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    background-attachment: fixed;
  }

  /* Text overlay styles */
  .capa-texto-card-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    color: white;
    opacity: 1; /* Inicialmente invisible */
    transition: opacity 0.5s ease;
    border-radius: 5px;
    cursor: pointer;
  }

  .title-card-hover {
    font-size: 30px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    margin: 0 20px; /* Espaciado interno */
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .list-card-hover {
    font-size: 16px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    margin: 0px 0px;

    text-align: left;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
  }

  /* Hover effects */
  .figure:hover .img-card-hover {
    transform: scale(1.1); /* Ampliar la imagen al pasar el mouse */
  }

  .figure:hover .capa-texto-card-hover {
    opacity: 0; /* Hacer visible la capa de texto */
  }
}

.Tittlesection1PC {
  font-size: 40px;
  color: #fff;
  text-align: left;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0px 50px;
  padding-top: 50px;
  animation: fadeIn 2s;
}

.filterSection1PC {
  position: absolute;
  background-color: #0000006b;
  width: 100%;
  height: 100%;
}

/* --------------------------- SECTION 2 ---------------------------------------- */
.Row1Section2PaqCom {
  margin: 3% 0px !important;
  padding: 10px 5%;
  width: 100%;
  height: 50%;
  border-radius: 10px;
}

.TittleSection2PaqCom {
  padding-left: 20px;
  padding-top: 3%;
  padding-bottom: 0px;
  margin: 0px 0px !important;
  color: #fff;
  z-index: 2;
  position: relative;
  width: 100%;
  height: auto;
}

.TittleSection2PaqCom h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
  padding: 10px 30px !important;
  margin: 0px 0px !important;
  text-align: left;
}

.ColSection2PaqCom {
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  /* width: 100%; */
  height: 100%;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  position: relative;
}

.ImgBgColPaqCom {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0px 0px;
  padding: 0px 0px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  position: relative;
}


.CapaTextoSection2PaqCom {
  background-color: #294963c3;
  z-index: 3;
  position: absolute;
  color: #fff;
  padding: 0px 0px;
  margin: 0px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 70%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #000000;
}

.CapaTextoSection2PaqCom h3 {
  font-weight: 400;
  font-size: 25px;
  color: white;
  display:flex;
  flex-direction: column;
  margin: 0px 0px;
  border-radius: 8px;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.filterSection2PaqCom{
  position: absolute;
  background-color: #00000090;
  width: 100%;
  height: 100%;
  z-index: 1;
}


@media screen and (max-width: 768px) {
  .Row1Section2PaqCom {
    margin: 0px 0px !important;
    padding: 0px 0px !important;
    width: 100%;
    height: 17%;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
  }
  
  .TittleSection2PaqCom {
    padding: 0px 20px !important;
    margin: 0px 0px !important;
    color: #fff;
    width: 100%;
    height: auto;
    /* background-color: red; */
  }
  
  .TittleSection2PaqCom h2 {
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: left;
    padding: 20px 20px !important;
    margin: 0px 0px !important;
    text-align: left;
  }
  
  .ColSection2PaqCom {
    padding: 0px 0px !important;
    margin: 10px 0px !important;
    text-align: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    
  }
  
  .ImgBgColPaqCom {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0px 0px;
    padding: 0px 0px;
    border-radius: 15px;
    position: relative;
  }
  
  
  .CapaTextoSection2PaqCom {
    background-color: #294963c4;
    z-index: 3;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    height: 90%;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #000000;
    position: absolute;
  }
  
  .CapaTextoSection2PaqCom h3 {
    font-weight: 400;
    font-size: 25px;
    color: white;
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px;
    width: 100%;
    border-radius: 8px;
  }
}
/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline {
overflow: hidden !important;
}

.timeline ul {
  padding: 50px 0;
  list-style-type: none;
}

.timeline ul li {
  position: relative;
  width: 10px;
  margin: 0 auto;
  padding-top: 50px;
  background: transparent;
}

.timeline h2 {
  display: block;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
  text-align: center;
}
.timeline p {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.IconsTimeLine{
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  margin: 0px 0px;
  padding: 10px 0px;


}

/* PROPIEDADES DE LOS BOTONES */
.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  z-index: 1;
  transition: background 1s ease-in-out;
}

.timeline ul li div {
  position: relative;
  width: 600px;
  padding: 10px;
  background: rgba(199, 203, 211, 0.696);
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-in-out;
  border-radius: 5px;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  left: -635px;
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li:nth-child(odd) div::before,
.timeline ul li:nth-child(even) div::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent #033b54 transparent transparent;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent #033b54;
}

.timeline ul li.in-view::after {
  background: #033b54;
}

.timeline ul li.in-view {
  background: #033b54;
  transition: 2s ease-in-out;
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

/* MEDIA QUERIES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 900px) {
  .timeline {
    width: 100%;
    height: 100%;
  }
  .timeline ul li div {
    width: 250px;
    height: auto;
  }

  .timeline ul li:nth-child(even) div {
    left: -289px;
  }
}

@media screen and (max-width: 780px) {
  .timeline {
    width: 100%;
    height: 100%;
  }

  .ColTL2 {
    width: 100%;
    height: 100% !important;
  }
  .timeline ul li {
    margin-left: 20px;
  }

  .timeline ul li div {
    width: calc(100vw - 91px);
  }

  .timeline ul li:nth-child(even) div {
    left: 25px;
  }

  .timeline ul li:nth-child(even) div::before {
    left: -25px;
    border-width: 8px 16px 8px 0;
    border-color: transparent transparent transparent;
  }
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}

/* 404NotFound.css */
.NotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 8px;
    text-shadow: 0 0 0.5rem rgba(40, 63, 90, 0.116);
    background-color: #2c4763b0;
    position: relative;
    overflow: hidden;
}

.NotFound-img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0;
    z-index: -1;
}

.NotFound-title,
.NotFound-text {
    z-index: 1;
}

.NotFound-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

.NotFound-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
}


@media screen and (max-width: 768px) {
    .NotFound-title {
        font-size: 2rem;
    }

    .NotFound-text {
        font-size: 1rem;
    }   
    
}


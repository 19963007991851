.button-transparent-left,
.button-transparent-right,
.button-transparent-section1Home,
.button-transparent-section2Home,
.button-transparent-section2Home-right,
.button-transparent-section2Home-left,
.button-transparent-general-center,
.button-transparent-center-NotFound,
.button-transparent-general-center-Nosotros,
.button-transparent-center-card-hover {
  background-color: rgba(0, 0, 0, 0.384) !important;
  box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.2);
  color: #ffffff !important;
  border: solid 0px !important;
  cursor: pointer;
  border-radius: 9px;
  transition: background-color 0.3s ease;
  padding: 10px 10px !important;
  text-align: center;
}

.button-transparent-left:hover,
.button-transparent-right:hover,
.button-transparent-section1Home:hover,
.button-transparent-section2Home:hover,
.button-transparent-section2Home-right:hover,
.button-transparent-section2Home-left:hover,
.button-transparent-general-center:hover,
.button-transparent-center-NotFound:hover,
.button-transparent-general-center-Nosotros:hover,
.button-transparent-center-card-hover {
  background-color: #ffffffad !important;
  color: #333 !important;

}

.button-transparent-left {
  width: 50%;
}

.button-transparent-right {
  width: 50%;
}

.button-null {
  background-color: transparent !important;
  color: transparent !important;
  visibility: hidden !important;
  cursor: none;
  visibility: hidden;
}


.button-transparent-general-center {
  position: absolute;
}

.button-transparent-general-center-Nosotros {
  position: absolute;
  transform: translate(10%, 15%);
}

.button-transparent-center-card-hover {
  position: absolute;
  transform: translate(0%, 5%);
  top: 60%;
}

.button-transparent-section2Home-left {
  position: relative;
  z-index: 5;
}

.button-transparent-section2Home-right {
  position: relative;
  z-index: 5;
}

.button-transparent-section1Home {
  margin-top: 2rem;
  letter-spacing: 3px;
  padding: 10px 30px !important;
}

@media screen and (max-width: 780px) {
  .button-transparent-general-center {
    position: absolute;
    transform: translate(-50%, 50%);
  }

  .button-transparent-center-NotFound {
    position: absolute;
    transform: translate(-50%, 50%);
  }

  .button-transparent-general-center-Nosotros {
    position: absolute;
    transform: translate(-50%, 20%);
  }
}

/* .button-transparent-left {
  top: 100%;
}

.button-transparent-right {
  top: 100%;
} */

@media screen and (max-width: 400px) {
  .button-transparent-section1Home {
    bottom: 0px;
    letter-spacing: 5px;
  }
}
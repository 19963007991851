/* ------------------ SECCIÓN 1 ------------------- */

.bgDivisionTextil {
  background-image: url("../../assets/media/PC/bgMaquinaTejHomePC.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  position: relative;
}

/* Largo del fondo y el filtro */
.bgDivisionTextil,
.filterDivText {
  height: 100%;
}

@media screen and (max-width: 768px) {

  .bgDivisionTextil,
  .filterDivText {
    height: 100%;
  }

}

.TittleSection1DivisionT {
  position: relative;
  font-size: 40px;
  font-weight: 500;
  color: #f5f5f5;
  text-align: left;
  width: 100%;
  padding-left: 5%;
  margin-top: 5rem;

}

.filterDivText {
  background-color: rgba(23, 55, 82, 0.929);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


/* ------------------ SECCIÓN 2 ------------------- */


.Row1Section2DivT {
  text-align: center;
  margin-top: 0px 0px !important;
  padding: 0px 0px !important;
  z-index: 1;
  position: relative;
  width: 90%;
  height: 50%;
  justify-content: center;
  left: 5%;
  top: 20%;
  border-radius: 10px;
}

.espaciadoContSection2DivT {
  padding: 10px 0px;
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.TittleSection2DivT {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  z-index: 3;
  width: 50%;
  left: 5%;
  top: 5rem;
}

.TittleSection2DivT h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  text-decoration-line: underline;
  text-align: left;
}

.ColSection2DivT {
  padding: 5px 5px !important;
  margin: 0px 0px !important;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.ImgBgColDivT {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  margin: 1px 1px;
  padding: 1px 1px;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
}


.CapaTextoSection2DivT {
  position: absolute;
  background-color: #294963d8;
  z-index: 2;
  color: #fff;
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 5%;
  width: 90%;
  height: 70%;
  top: 15%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #000000;
}


.CapaTextoSection2DivT h3 {
  position: absolute;
  /* background-color: #0fd43a93; */
  z-index: 3;
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: center;
  padding: 0px 0px;
  margin: 0px 0px;
  text-align: center;
  border-radius: 8px;
  letter-spacing: 1px;
  word-wrap: break-word;
}

.filter1DivTSection2 {
  position: absolute;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.filter2DivTSection2 {
  position: absolute;
  background-color: #22659b36;
  width: 100%;
  height: 100%;
  z-index: 3;
}

@media screen and (max-width: 768px) {

  .Row1Section2DivT {
    text-align: center;
    margin-top: 0px 0px;
    padding: 0px 0px;
    z-index: 1;
    position: relative;
    background-color: #294963;
    width: 100%;
    height: 100%;
    justify-content: center;
    left: 10%;
    top: 5% !important;
  }

  .TittleSection2DivT {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #fff;
    position: relative;
    z-index: 3;
    width: 80% !important;
    left: 5%;
  }

  .TittleSection2DivT h2 {
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: left;
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-align: left;
  }

  .ColSection2DivT {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    text-align: center;
    width: 100%;
    height: 25% !important;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .ImgBgColDivT {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    margin: 0px 0px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .CapaTextoSection2DivT {
    position: absolute;
    background-color: #294963cc;
    z-index: 2;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 10%;
    width: 100%;
    height: 100%;
    top: 15%;
    word-spacing: normal;
    box-shadow: 0px 0px 10px 0px #000000;

  }


  .filter1DivTSection2 {
    position: absolute;
    background-color: #00000080;
    width: 100%;
    height: 100%;
    z-index: 1;

  }

  .filter2DivTSection2 {
  position: absolute;
  background-color: #1388e936;
  width: 100%;
  height: 100%;
  z-index: 3;
  }

}

@media screen and (max-width: 768px) {



  .Row1Section2DivT {
    text-align: center;
    margin-top: 0px 0px !important;
    padding: 0px 0px !important;
    z-index: 1;
    position: relative;
    background-color: #294963;
    width: 80%;
    height: 40%;
    justify-content: center;
    left: 10%;
    top: 10%;
  }

  .espaciadoContSection2DivT {
    padding: 10px 0px;
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .TittleSection2DivT {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #fff;
    position: relative;
    z-index: 3;
    width: 50%;
    left: 5%;
    top: 3rem;
  }

  .TittleSection2DivT h2 {
    font-weight: 400;
    font-size: 40px;
    color: white;
    text-align: left;
    padding: 0px 0px !important;
    margin: 0px 0px !important;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-align: left;
  }

  .ColSection2DivT {
    padding: 5px 5px !important;
    margin: 0px 0px !important;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  .ImgBgColDivT {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    margin: 0px 0px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .CapaTextoSection2DivT {
    position: absolute;
    background-color: #294963cc;
    z-index: 2;
    color: #fff;
    padding: 0px 0px;
    margin: 0px 0px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 10%;
    width: 80%;
    height: 60%;
    top: 15%;
    word-spacing: normal;
    box-shadow: 0px 0px 10px 0px #000000;

  }

}



/* ------------------ SECCIÓN 3 ------------------- */

.filter1DivTSection3 {
  position: absolute;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  z-index: 1;

}

.TittleSection3DivT {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  z-index: 3;
  width: 50%;
  left: 5%;
  top: 5rem;
}
.TittleSection3DivT h2 {
  font-weight: 400;
  font-size: 40px;
  color: white;
  text-align: left;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
  text-decoration-line: underline;
  text-align: left;
}

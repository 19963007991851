/* 404NotFound.css */
.NotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: relative;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 8px;
    text-shadow: 0 0 0.5rem rgba(40, 63, 90, 0.116);
    background-color: #2c4763b0;
    position: relative;
    overflow: hidden;
}

.NotFound-img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0;
    z-index: -1;
}

.NotFound-title,
.NotFound-text {
    z-index: 1;
}

.NotFound-title {
    font-size: 3rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

.NotFound-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
}


@media screen and (max-width: 768px) {
    .NotFound-title {
        font-size: 2rem;
    }

    .NotFound-text {
        font-size: 1rem;
    }   
    
}

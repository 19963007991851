.ContainerCardInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
}

.cardInfo {
  --bg: #f7f7f8;
  --hover-bg: #3a5877de;
  --hover-text: #f5f5f5;
  width: 100%;
  max-width: 100%; /* Ajusta este valor según sea necesario */
  height: 100%;
  max-height: 400px;
  text-align: center;
  background: var(--bg);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0.6, 0.4, 0, 1), transform 0.15s ease;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.cardInfo > strong {
  display: block;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.035em;
}

.cardInfo span {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hover-text);
  border-radius: 5px;
  font-weight: bold;
  top: 100%;
  transition: all 0.4s cubic-bezier(0.6, 0.4, 0, 1);
  cursor: pointer;
  text-align: center;
  padding: 0 10px;
}

.cardInfo:hover span {
  top: 0;
  font-size: 1.2em;
}

.cardInfo:hover {
  background: var(--hover-bg);
}

.cardInfo:hover > div,
.cardInfo:hover > strong {
  opacity: 0;
}

.icon img {
  width: 100%;
  height: auto;
  max-height: 100px;
  min-height: 60px;
  height: 70px;
}

@media screen and (max-width: 768px) {
  .cardInfo {
    padding: 1rem;
  }

  .ContainerCardInfo {
    padding: 1rem;
  }
}

.floatWhatsapp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 50px;
  right: 40px;
  border-radius: 50%; /* Asegúrate de que sea un círculo */
  text-align: center;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-icon {
  transition: transform 0.3s, fill 0.3s;
}

.floatWhatsapp:hover .whatsapp-icon {
  transform: scale(1.1);
}


/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline {
overflow: hidden !important;
}

.timeline ul {
  padding: 50px 0;
  list-style-type: none;
}

.timeline ul li {
  position: relative;
  width: 10px;
  margin: 0 auto;
  padding-top: 50px;
  background: transparent;
}

.timeline h2 {
  display: block;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
  text-align: center;
}
.timeline p {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.IconsTimeLine{
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  margin: 0px 0px;
  padding: 10px 0px;


}

/* PROPIEDADES DE LOS BOTONES */
.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  z-index: 1;
  transition: background 1s ease-in-out;
}

.timeline ul li div {
  position: relative;
  width: 600px;
  padding: 10px;
  background: rgba(199, 203, 211, 0.696);
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-in-out;
  border-radius: 5px;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  left: -635px;
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li:nth-child(odd) div::before,
.timeline ul li:nth-child(even) div::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent #033b54 transparent transparent;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent #033b54;
}

.timeline ul li.in-view::after {
  background: #033b54;
}

.timeline ul li.in-view {
  background: #033b54;
  transition: 2s ease-in-out;
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

/* MEDIA QUERIES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 900px) {
  .timeline {
    width: 100%;
    height: 100%;
  }
  .timeline ul li div {
    width: 250px;
    height: auto;
  }

  .timeline ul li:nth-child(even) div {
    left: -289px;
  }
}

@media screen and (max-width: 780px) {
  .timeline {
    width: 100%;
    height: 100%;
  }

  .ColTL2 {
    width: 100%;
    height: 100% !important;
  }
  .timeline ul li {
    margin-left: 20px;
  }

  .timeline ul li div {
    width: calc(100vw - 91px);
  }

  .timeline ul li:nth-child(even) div {
    left: 25px;
  }

  .timeline ul li:nth-child(even) div::before {
    left: -25px;
    border-width: 8px 16px 8px 0;
    border-color: transparent transparent transparent;
  }
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
